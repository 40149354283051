"use client"

import { useRef } from "react"
import clsx from "clsx"

import type { GID } from "@unlikelystudio/commerce-connector"
import { Spinner } from "@unlikelystudio/react-abstract-components"

import { useGetShopTheLookItems } from "~/hooks/useGetShopTheLookItems"
import PanelHeader from "~/components/ui/Panels/PanelHeader"
import type { ShopTheLook } from "~/components/ui/Panels/ShopTheLook/_data/serializer"
import ShopThelookContent from "~/components/ui/Panels/ShopTheLook/components/ShopTheLookContent"
import type { TProduct } from "~/components/ui/ProductHeader/_data/types"
import { ClientViewItemList } from "~/providers/GTMTrackingProvider/ClientViewItemList"
import { serializeViewedItemListPayload } from "~/providers/GTMTrackingProvider/constants"
import { getProductTrackingDataWithCollections } from "~/providers/GTMTrackingProvider/utils/get-product-tracking-data-with-collections"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useCollectionTree } from "~/managers/CollectionsTreeManager/hooks"
import { Panel } from "~/managers/PanelManager"

import * as css from "./styles.css"

type ShopTheLookPanelProps = {
  gids: GID[]
  items?: ShopTheLook
} & (
  | { fromProductHeaderShopTheLook?: false; serializedProduct?: never }
  | { fromProductHeaderShopTheLook?: true; serializedProduct: Pick<TProduct, "handle" | "title"> }
)

function ShopTheLookPanel({ items, gids, fromProductHeaderShopTheLook, serializedProduct }: ShopTheLookPanelProps) {
  const panelRef = useRef<HTMLDivElement>(null)

  const [collectionTree] = useCollectionTree()
  const t = useTranslate()

  const { data: products, isLoading } = useGetShopTheLookItems(
    { gids },
    {
      initialData: items,
    }
  )

  return (
    <Panel className={clsx(css.wrapper)} zIndex={60} clickOutsideRef={panelRef}>
      <div className={clsx(css.ShopTheLookPanel)} ref={panelRef}>
        <PanelHeader className={clsx(css.header)} title={t("shop_the_look")} size="large" />
        {isLoading ? (
          <Spinner className={clsx(css.spinner)} />
        ) : (
          <ShopThelookContent
            className={clsx(css.content)}
            items={products}
            fromProductHeaderShopTheLook={fromProductHeaderShopTheLook}
          />
        )}
      </div>

      <ClientViewItemList
        payload={{
          item_list_id: serializedProduct?.handle ? `${serializedProduct.handle}-shop-the-look` : "shop-the-look",
          item_list_name: serializedProduct?.title ? `${serializedProduct.title} - Shop The Look` : "Shop The Look",
          items:
            products?.map((product, index) => {
              const itemTrackingDataWithCollections = getProductTrackingDataWithCollections(
                product.trackingData,
                product.collectionsHandles,
                collectionTree
              )
              return serializeViewedItemListPayload(itemTrackingDataWithCollections, {
                index,
                item_list_id: product.handle ?? "",
                item_list_name: product.title ?? "",
              })
            }) ?? [],
        }}
      />
    </Panel>
  )
}

export default ShopTheLookPanel
export { type ShopTheLookPanelProps }
