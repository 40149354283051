"use client"

import { useEffect, type DependencyList } from "react"

import type { ViewedItemListPayload } from "~/providers/GTMTrackingProvider/constants"
import { useGlobalTrackingProps } from "~/providers/GTMTrackingProvider/hooks/use-get-tracking-globals"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"

type ClientViewItemListProps = {
  payload: ViewedItemListPayload
  deps?: DependencyList
}

export function ClientViewItemList({ payload, deps = [] }: ClientViewItemListProps) {
  const { sendEvent } = useTracking()
  const globaltracking = useGlobalTrackingProps()
  useEffect(() => {
    sendEvent("view_item_list", { ecommerce: { ...globaltracking, ...payload } })
  }, deps)

  return null
}
