import type { Nullish, ObjectValues } from "~/@types/generics"

export type PageName =
  | "Customer Info Page"
  | "Customer Addresses Page"
  | "Customer Orders Page"
  | "Enable Account Page"
  | "Forgot Password Page"
  | "Login Page"
  | "Register Page"
  | "Reset Password Page"
  | "Collection Page"
  | "Product Page"
  | "Legals Page"
  | "Universal Page"
  | "Configurator Page"
  | "Contact Page"
  | "Search Page"
  | "Home Page"

export type ViewedItemListItemProps = {
  index: number
  item_list_id: string
  item_list_name: string
}

export type ViewedItemListItem = CartLineItemPayload & ViewedItemListItemProps

export type EcommerceEvent<TPayload = {}> = {
  ecommerce: TrackingGlobals & TPayload
}

export type ViewedItemListPayload = {
  item_list_id: string
  item_list_name: string
  items: ViewedItemListItem[]
}

export function serializeViewedItemListPayload(
  itemTrackingData: CartLineItemPayload,
  trackingData: ViewedItemListItemProps
) {
  return {
    ...itemTrackingData,
    ...trackingData,
  }
}

type CategoryAttribute = `item_category_${number}`

export type CartLineItemPayload = {
  item_name: string
  affiliation: string
  description: string
  item_brand: string
  item_id: string
  item_product_id: Nullish<string>
  item_variant?: string
  item_variant_id?: Nullish<string>
  quantity: number
  price: number
  discount: number
  shop_the_look?: boolean
  [key: CategoryAttribute]: CategoryAttribute
}

type PagePayload = {
  pageTitle?: PageName
  pagePath: string
}

type UserPayload = {
  user_id: string
}

type TrackingGlobals = {
  currency: string
  value: number
  content_type: string
}

// TODO: add all events data
export type TrackingEvents = {
  view_item_list: EcommerceEvent<ViewedItemListPayload & PagePayload>
  view_item: EcommerceEvent<{ items: CartLineItemPayload[] } & PagePayload>
  add_to_cart: EcommerceEvent<{ items: CartLineItemPayload[] }>
  remove_from_cart: EcommerceEvent<{ items: CartLineItemPayload[] }>
  view_cart: EcommerceEvent<{ items: CartLineItemPayload[] }>
  search: { search_term: string }
  page_view: PagePayload

  begin_checkout: { items: CartLineItemPayload[] }
  login: UserPayload
  register: UserPayload
  newsletter: UserPayload
}

export type TrackingEventKey = keyof TrackingEvents
export type EventPayload = ObjectValues<TrackingEvents>
